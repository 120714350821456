import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import Axios from 'axios'
import VueMeta from 'vue-meta'
import DeleList from './components/DeleList.vue'
import DeleHome from './components/DeleHome.vue'
import PublicDele from './components/PublicDele.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { VueMasonryPlugin } from 'vue-masonry';
import VueEditor from 'vue2-editor';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import store from './store' // Import the Vuex store

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

Vue.prototype.$axios = Axios;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMasonryPlugin)
Vue.use(VueEditor);
Vue.use(CKEditor);
Vue.use(VueMeta);

Vue.config.productionTip = false

Vue.use(Router)

const router = new Router({
  mode: 'history', // Lägg till detta för att använda HTML5 History Mode
  routes: [
    {
      path: '/',
      name: 'DeleHome',
      component: DeleHome,
      props: true
    },
    {
      path: '/delegater',
      name: 'DeleList',
      component: DeleList,
    },
    {
      path: '/publik/:slug',
      name: 'PublicDele',
      component: PublicDele,
    },
  ]
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
