<template>
  <div style="text-align:left;padding: 30px;">
    <div class="card" v-if="loading">
    <b-spinner></b-spinner>
  </div>
    <div v-else class="card">
    <h1>Delegater</h1>
    <label for="delegatFilter">Filtrera fram delegat:</label>
    <input type="text" id="delegatFilter" v-model="searchQuery" name="Delegatfilter" placeholder="Delegat..." class="form-control mb-3">
        <table class="table table-striped table-bordered">
        <thead>
            <tr>
            <th style="width: 50px;text-align: center;">Välj</th>
            <th style="width: 95%;">Titel</th>
            </tr>
        </thead>
          <tbody>
            <tr v-for="(user, index) in filteredUsers" :key="index">
              <td style="text-align:center;"><b-form-checkbox :value="user" v-model="selectedUsers" /></td>
              <td style="text-decoration:underline; font-weight: bold;"><span v-b-modal.modal-5 @click="matchDelegat(user)">{{ user.titel }}</span></td>
            </tr>
          </tbody>
        </table>
        <div>
            <b-button @click="selectAllUsers" class="btn btn-info">Markera alla</b-button>
            <b-button @click="deselectAllUsers" class="btn btn-info" style="margin-left: 15px;">Avmarkera alla</b-button>
            <b-button
              @click="showSelectedDelegatTables"
              class="btn btn-primary"
              style="margin-left: 15px;"
              :disabled="selectedUsers.length === 0">
              Visa valda delegaters beslut
            </b-button>
        </div>
    </div>
    <b-modal id="modal-5" size="xl" ref="showDelegat-modal" @hidden="onHidden" :title="this.currentDelegat" hide-footer hide-close>
      <b-tabs>
        <b-tab title="Delegat" v-if="this.matchDelegations != ''">
            <h6 style="padding-top: 15px;">{{ this.currentDelegat }} är delegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Typ av ärenden</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchDelegations" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td><router-link :to="'/publik/' + matched.slug">{{ matched.avsnitt }}</router-link></td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>{{ matched.arendetyp }}</td>
                    </tr>
                </tbody>
            </table>
        </b-tab>
        <b-tab title="Vidaredelegat" v-if="this.matchVd != ''">
            <h6 style="padding-top: 15px;">{{ this.currentDelegat }} är vidaredelegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Ärenden som vidaredelegeras</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchVd" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td>
                          <router-link :to="'/publik/' + matched.slug">{{
                            matched.avsnitt
                          }}</router-link>
                        </td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>
                          <ul v-if="matched.arendenVidare.length > 1">
                            <li v-for="arende in matched.arendenVidare" :key="arende">{{ arende }}</li>
                          </ul>
                          <div v-else>
                             <div v-for="arende in matched.arendenVidare" :key="arende">
                              {{ arende }}
                            </div>
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-tab>
        <div v-if="this.matchVd == '' && this.matchDelegations == ''">
          <h6 style="padding-top: 15px;">{{ this.currentDelegat }} får inte fatta delegationsbesut</h6>
        </div>
      </b-tabs>
    </b-modal>
    <b-modal id="modal-7" size="xl" title="Valda Delegater" hide-footer @hidden="onHiddenModal7">
      <b-tabs>
        <b-tab title="Delegat">
          <table class="table table-striped table-bordered">
        <thead style="background: #ff8204; color: white;">
            <tr>
            <th>Delegat</th>
            <th>Delegationsordning</th>
            <th>Avsnitt</th>
            <th>Tabell</th>
            <th>Nr</th>
            <th>Typ av ärenden</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in selectedUsersDelegations" :key="user.key">
            <td>{{ user.titel }}</td>
            <td>{{ user.delegationsordning }}</td>
            <td>{{ user.avsnitt }}</td>
            <td>{{ user.tabell }}</td>
            <td>{{ user.nr }}</td>
            <td>{{ user.arendetyp }}</td>
            </tr>
        </tbody>
    </table>
        </b-tab>
        <b-tab title="Vidaredelegat">
          <table class="table table-striped table-bordered">
        <thead style="background: #ff8204; color: white;">
            <tr>
            <th>Delegat</th>
            <th>Delegationsordning</th>
            <th>Avsnitt</th>
            <th>Tabell</th>
            <th>Nr</th>
            <th>Typ av ärenden</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in selectedUsersDelegations" :key="user.key">
            <td>{{ user.titel }}</td>
            <td>{{ user.delegationsordning }}</td>
            <td>{{ user.avsnitt }}</td>
            <td>{{ user.tabell }}</td>
            <td>{{ user.nr }}</td>
            <td>{{ user.arendetyp }}</td>
            </tr>
        </tbody>
    </table>
        </b-tab>
      </b-tabs>
  </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
    showModal: false,
      newUser: {
        titel: "",
        kommentar: ""
      },
      selectedUsers: [],
      selectedUsersDelegations: [],
      users: [],
      matchDelegations: [],
      matchVd: [],
      currentDelegat: "",
      currentDelegatKommentar: "",
      currentUpdateDelegat: "",
      currentId: null,
      deletedUser: null,
      searchQuery: '',
      loading: true,
    };
  },
  methods: {
    matchDelegat(user) {
      this.currentDelegat = user.titel;
      this.currentDelegatKommentar = user.kommentar;

      // Använd datan från storen
      const delegations = this.$store.state.publicDelegations;

      // Töm eventuella tidigare resultat
      this.matchVd = [];
      this.matchDelegations = [];

      delegations.forEach((delegation) => {
        delegation.rows?.forEach((avsnitt) => {
          avsnitt.allTables?.forEach((allTable) => {
            allTable.table?.forEach((table) => {
              // Hitta index för alla vidaredelegat som matchar user.titel
              const vidaredelegatIndexes = table.vidaredelegat?.reduce((acc, delegat, index) => {
                if (delegat === user.titel) {
                  acc.push(index);
                }
                return acc;
              }, []);

              // Använd de hittade indexen för att få motsvarande arendenVidare
              if (vidaredelegatIndexes.length > 0) {
                const arendenVidareMatches = vidaredelegatIndexes.map(index => table.arendenVidare[index]);

                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendenVidare: arendenVidareMatches, // Spara som en array
                  slug: delegation.slug
                };
                this.matchVd.push(delegationMatch);
              }

              // Matchning för delegat
              if (table.delegat === user.titel) {
                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendetyp: table.arendetyp,
                  slug: delegation.slug
                };
                this.matchDelegations.push(delegationMatch);
              }
            });
          });
        });
      });
    },
    showSelectedDelegatTables() {
      const selectedUsers = [...this.selectedUsers]; // Kopiera den valda användararrayen för att undvika referensproblem

      const promises = selectedUsers.map(user => this.fetchUserDelegations(user));
      Promise.all(promises).then(delegations => {
        this.selectedUsersDelegations = delegations.flat(); // Anta att du har en ny dataegenskap för att lagra dessa
        this.$bvModal.show('modal-7');
      });
    },
    fetchUserDelegations(user) {
      const delegatTitel = user.titel;
      const userDelegations = []; // Skapa en ny array för att hålla användarens delegationer

      // Använd datan från Vuex store direkt
      const delegations = this.$store.state.publicDelegations;

      delegations.forEach((delegation) => {
        delegation.rows.forEach((avsnitt) => {
          avsnitt.allTables.forEach((allTable) => {
            allTable.table.forEach((table) => {
              if (table.delegat.includes(delegatTitel) || (table.vidaredelegat && table.vidaredelegat.includes(delegatTitel))) {
                const delegationInfo = {
                  titel: user.titel,
                  delegationsordning: delegation.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendetyp: table.arendetyp
                };
                userDelegations.push(delegationInfo); // Lägg till delegationen i arrayen
              }
            });
          });
        });
      });

      return Promise.resolve(userDelegations); // Returnera en resolved promise med användarens delegationer
    },
    selectAllUsers() {
      this.selectedUsers = [...this.filteredUsers];
    },
    deselectAllUsers() {
      this.selectedUsers = [];
    },
    onHidden() {
        this.matchDelegations = [];
        this.matchVd = [];
    },
    onHiddenModal7() {
    this.selectedUsers = [];
    },
    getData() {
      if (this.$store.state.publicDelegations.length === 0) {
        this.loading = true;
        this.$store.dispatch("fetchPublicDelegations").then(() => {
          this.loading = false;
        });
      }
    },
    reloadData() {
      this.loading = true;
      this.$store.dispatch("fetchPublicDelegations").then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('fetchPublicDelegations'),
      this.$store.dispatch('fetchDelegates')
    ]).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      console.error('Error fetching data');
    });
  },
  computed: {
    publicDelegations() {
      return this.$store.state.publicDelegations;
    },
    ...mapState(['delegations']), // Antag att 'delegations' håller din lista av delegater
    filteredUsers() {
      return this.delegations
      .filter(user => user.titel.toLowerCase().includes(this.searchQuery.toLowerCase()))
      .sort((a, b) => a.titel.localeCompare(b.titel)); // Sorterar i bokstavsordning baserat på 'titel'
    },
    generateId() {
      return (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, "-");
      };
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

table td,
table th,
table .form-control {
  font-size: 13px;
}

table td a {
  color: black;
}

.popover-header {
    font-size: 15px!important;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>